<template>
	<div>
		<h1 class="text-4xl text-center py-5">{{ aboutUs.title }}</h1>
		<container max-w="6xl mx-10">
			<div v-html="aboutUs.content"></div>
		</container>
	</div>

</template>

<script>
import { mapGetters } from 'vuex';
import Container from "../../layouts/container";
import axios from "../../../store/axios";

export default {
	name: "About",
	components: {
		Container,
	},
	data: {

	},
	computed: {
		...mapGetters({
            aboutUs: 'pages/aboutUs',
        }),
	},
	created() {

        axios().get('/cookie-test' );
        axios().get('/cookie-test-get' );

        this.$store.dispatch('pages/AboutUsPage');
    },
};
</script>

<style scoped>

</style>
